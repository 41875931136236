<template>
  <div class="tags">
    <div class="tag" v-for="(item, i) in items" :key="i">
      <div class="tag__numbers">
        <div class="tag__aisle-and-unit">
          <span class="tag__number tag__number--unit">{{ item.aisle }}</span>
          <span class="tag__number tag__number--unit">{{ item.unit }}</span>
        </div>
        <div class="tag__shelve-and-place" :class="`tag__shelve-and-place--${item.shelve}`">
          <span class="tag__number tag__number--shelve">{{ item.shelve }}</span>
          <span class="tag__number tag__number--place">{{ item.place }}</span>
        </div>
      </div>
      <div class="tag__barcode-container">
        <svg
          class="barcode tag__barcode"
          :jsbarcode-value="`${item.aisle}${item.unit}${item.shelve}${item.place}`"
        ></svg>
      </div>
    </div>
  </div>
</template>

<script>
import JsBarcode from 'jsbarcode'

export default {
  data() {
    return {
      aisles: ['K', 'L'],
      units: [1, 2],
      shelves: [0, 1, 2, 3, 4, 5],
      places: [0]
    }
  },
  mounted() {
    JsBarcode('.barcode')
      .options({ format: 'CODE128A', displayValue: false, width: 3 })
      .init()
  },
  computed: {
    items() {
      const items = []
      this.aisles.forEach(aisle => {
        this.units.forEach(unit => {
          this.shelves.forEach(shelve => {
            this.places.forEach(place => {
              items.push({ aisle, unit, shelve, place })
            })
          })
        })
      })
      return items
    }
  }
}
</script>

<style lang="scss">
@media print {
  @page {
    size: A4;
    margin: 0mm;
  }
}

$tagWidth: 52.5mm;
$tagHeight: 29.7mm;

.tag {
  width: $tagWidth * 2;
  height: $tagHeight;
  display: flex;
  float: left;
  align-items: center;
  page-break-inside: avoid;

  &__numbers {
    font-size: 20px;
    width: $tagWidth;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
  }

  &__aisle-and-unit {
    background: #ccc;
    border-radius: 3mm 0 0 3mm;
    color: #000;
    padding: 0 3mm;
    height: $tagHeight * 0.667;
    line-height: $tagHeight * 0.667;
    text-align: center;
  }

  &__shelve-and-place {
    background: #ccc;
    border-radius: 0 3mm 3mm 0;
    padding: 0 3mm;
    height: $tagHeight * 0.667;
    line-height: $tagHeight * 0.667;

    &--1 {
      background: #cfc;
    }

    &--2 {
      background: #ccf;
    }

    &--3 {
      background: #fcc;
    }

    &--4 {
      background: #cff;
    }

    &--5 {
      background: #fcf;
    }

    &--6 {
      background: #ffc;
    }

    .tag__number {
      display: inline-block;
      text-align: center;
    }
  }

  &__barcode-container {
    width: $tagWidth;
    display: flex;
    justify-content: center;
    flex-grow: 1;
  }

  &__barcode {
    width: auto;
    height: $tagHeight - 10mm;
  }
}
</style>
